<template>
    <div class="card ">
        <div class="card-header border-0">
            <h3 class="card-title">
                <i class="fas fa-th mr-1"></i>
                Flota
            </h3>
            <i class="fas fa-info" style="float:right" @click="toggle($event)"></i>

        </div>
        <div class="card-body">
            <div class="row">
                <div class="col-md-3">
                    <div class="row">
                        <Knob v-model="optidrive" readonly :min="0" valueColor="#00a65a" :max="100" />
                        <p>Optidrive</p>
                    </div>
                    <span>
                        <Chart type="bar" ref="opti" :data="datosoptidrive" :options="opcionesoptidrive"
                            class="h-10rem" />
                    </span>
                </div>
                <div class="col-md-3">
                    <div class="row">
                        <Knob v-model="velocidadmax" readonly :min="0" valueColor="#f33" :max="180" />
                        <p>Velocidad Máxima</p>
                    </div>
                    <span>
                        <Chart type="bar" :data="datosvelmax" :options="opcionesoptidrive" class="h-10rem" />
                    </span>
                </div>
                <div class="col-md-3">
                    <div class="row">
                        <Knob v-model="velocidadmed" readonly :min="0" valueColor="#f93" :max="180" />
                        <p>Velocidad Media</p>
                    </div>
                    <span>
                        <Chart type="bar" :data="datosvelmed" :options="opcionesoptidrive" class="h-10rem" />
                    </span>
                </div>
                <div class="col-md-3">

                    <Chart type="doughnut" :data="chartData" :options="lightOptions" class="donut" />
                    <p>T.ralentí/ruta (horas)</p>

                    <span>
                        <Chart type="bar" :data="datosruta" :options="opcionesoptidrive" class="donutdos" />

                    </span>
                </div>

            </div>
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-4">
                    <i class="fas fa-road"></i>&nbsp;Distancia {{ metrosrecorridos }}&nbsp;Km
                    <span>
                        <Chart type="bar" :data="datosdist" :options="opcionesoptidrive" class="distancia" />
                    </span>
                </div>
                <div class="col-md-4">
                    <i class="fas fa-tachometer-alt"></i>&nbsp;Combustible {{ combustible }}&nbsp;litros
                    <Chart type="bar" :data="datoscomb" :options="opcionesoptidrive" class="distancia" />

                </div>
            </div>
            <OverlayPanel ref="info6">
                <h4 style="text-align:center">Flota</h4>
                <ul>
                    <li><strong>Optidrive:</strong> Media puntuación de conducción general del día anterior (de 0 a 100)
                    </li>
                    <li><strong>Velocidad máxima:</strong> Media de velocidad máxima del día anterior (km/h)</li>
                    <li><strong>Velocidad media:</strong> Media de velocidad media del día anterior (km/h)</li>
                    <li><strong>Tiempo en ralentí/ruta:</strong> Suma del tiempo en ralentí/ruta del día anterior
                        (horas)</li>
                    <li><strong>Distancia:</strong> Suma de la distancia recorrida del día anterior (km).</li>
                    <li><strong>Combustible:</strong> Suma del consumbo de combultible del día anterior (litros).</li>
                </ul>

            </OverlayPanel>
        </div>
        <!-- /.card-body -->
    </div>
</template>
<script>
import OverlayPanel from 'primevue/overlaypanel';
import { PwgsApi } from '../../../../../services/PwgsApi';
import Knob from 'primevue/knob';

export default {
    components: {
        Knob,OverlayPanel
    },
    data() {
        return {
            velocidadmax: '',
            velocidadmed: '',
            metrosrecorridos: '----',
            combustible: '----',
            estadisticas: [],
            optidrive:[],
            chartData: {
                labels: ['Ruta', 'Ralenti'],
                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#009abf", "#f39c12"],
                        hoverBackgroundColor: ["#009abf", "#f39c12"]
                    }
                ]
            },
            lightOptions: {
                
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        text: "Tiempo en ralentí/ruta (horas)",
                        display: false,
                    }
                },
            },
            lightOptionsdos: {
                legend: {
                    display: false
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        text: "Tiempo en ralentí/ruta (horas)",
                        display: false,
                    }
                },
            },
      
            datosoptidrive: {
                labels: [],

                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#00a65a"],
                        borderColor: ["#00a65a"]
                    }
                ]
            },
            datosvelmax: {
                labels: [],

                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#f33"],
                        borderColor: ["#f33"]
                    }
                ]
            },
            datosvelmed: {
                labels: [],

                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#f93"],
                        borderColor: ["#f93"]
                    }
                ]
            },
            datosdist: {
                labels: [],

                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#36c"],
                        borderColor: ["#36c"]
                    }
                ]
            },
            datoscomb: {
                labels: [],

                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#36c"],
                        borderColor: ["#36c"]
                    }
                ]
            },
            datosruta: {
                labels: ['Ruta', 'Ralenti'],

                datasets: [
                    {
                        data: [],
                        backgroundColor: ["#009abf"],
                        borderColor: ["#009abf"]
                    },
                    {
                        data: [],
                        backgroundColor: ["#f39c12"],
                        borderColor: ["#f39c12"]
                    }
                   
                ]
            },
           
            opcionesoptidrive: {
                responsive: true,
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        text: "Optidrive",
                        display: false,
                    }
                },
                scales: {
                    x: { display: false, },
                    y: { display: true, }
                }
            }
        }
    },
    
    methods: {

        async cargardatos() {
            
            const api = new PwgsApi();
            this.estadisticas = await api.get('estadisticas');
            this.optidrive = this.estadisticas.graficas.flota.valores.operario_coche_optidrive.slice(-1);
            this.velocidadmax = this.estadisticas.graficas.flota.valores.operario_coche_velocidad_maxima.slice(-1);
            this.velocidadmed = this.estadisticas.graficas.flota.valores.operario_coche_velocidad_media.slice(-1);
            this.chartData.datasets[0].data[0] = this.estadisticas.graficas.flota.valores.operario_coche_tiempo_en_ruta.slice(-1);
            this.chartData.datasets[0].data[1] = this.estadisticas.graficas.flota.valores.operario_coche_tiempo_en_ralenti.slice(-1);
            this.datosoptidrive.datasets[0].data = this.estadisticas.graficas.flota.valores.operario_coche_optidrive;
            this.datosoptidrive.labels = this.estadisticas.graficas.flota.valores.operario_coche_optidrive;
            this.datosvelmax.datasets[0].data = this.estadisticas.graficas.flota.valores.operario_coche_velocidad_maxima;
            this.datosvelmax.labels = this.estadisticas.graficas.flota.valores.operario_coche_velocidad_maxima;
            this.datosvelmed.datasets[0].data = this.estadisticas.graficas.flota.valores.operario_coche_velocidad_media;
            this.datosvelmed.labels = this.estadisticas.graficas.flota.valores.operario_coche_velocidad_media;
            this.datoscomb.datasets[0].data = this.estadisticas.graficas.flota.valores.operario_coche_fuel_usado;
            this.datoscomb.labels = this.estadisticas.graficas.flota.valores.operario_coche_fuel_usado;    
            this.datosdist.datasets[0].data = this.estadisticas.graficas.flota.valores.operario_coche_metros_recorridos;
            this.datosdist.labels = this.estadisticas.graficas.flota.valores.operario_coche_metros_recorridos;
            this.datosruta.datasets[1].data = this.estadisticas.graficas.flota.valores.operario_coche_tiempo_en_ralenti;
            this.datosruta.datasets[0].data = this.estadisticas.graficas.flota.valores.operario_coche_tiempo_en_ruta;
            this.datosruta.labels = this.estadisticas.graficas.flota.valores.operario_coche_tiempo_en_ruta;    
            this.metrosrecorridos = parseInt(this.estadisticas.graficas.flota.valores.operario_coche_metros_recorridos.slice(-1));
            this.combustible = parseInt(this.estadisticas.graficas.flota.valores.operario_coche_fuel_usado.slice(-1));
        },
        toggle(event) {
            this.$refs.info6.toggle(event);
        },

    },
    watch: {

    },
    mounted() {
        this.cargardatos();
    }
}
</script>
<style>
.distancia{
    width:100px;
}
.donut{
        width: 100px;
}

</style>
